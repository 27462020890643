import { NgModule } from '@angular/core';

import { SharedModule } from './../../shared/shared.module';

import { NavbarPrimaryComponent } from '../components/navbar-primary/navbar-primary.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [NavbarPrimaryComponent, FooterComponent],
  imports: [SharedModule],
  exports: [NavbarPrimaryComponent, FooterComponent],
})
export class ComponentsModule {}
