<p-multiSelect
  #multiSelect
  [options]="valueList"
  [showToggleAll]="checkFilterSelectAll()"
  optionLabel="label"
  appendTo="body"
  [loading]="isLoading"
  [virtualScroll]="true"
  [filter]="true"
  [virtualScrollItemSize]="43"
  [style]="{ width: '100%' }"
  [panelStyle]="{ width: '300px'}"
  [scrollHeight]="'300px'"
  [showClear]="true"
  [resetFilterOnHide]="true"
  [emptyMessage]="isLoading? 'Loading...' : 'No results' "
  (onFilter)="onFilter($event)"
  (onPanelShow)="fetchFilter($event)"
  (onChange)="filterChange($event.value)"
  (onClear)="clearSelection(); multiSelect.hide()"
  (onSelectAllChange)="onSelectAllChange($event)"
  [(ngModel)]="selectedValues"
  class="se-filter-dropdown"
  [ngClass]="{
    'se-filter-dropdown__filtering': selectedValues?.length > 0
  }"
>
  <ng-template let-value pTemplate="item" *ngIf="!isLoading">
    <div class="flex align-items-center gap-2" >
      <div>{{ value.label }}</div>
    </div>
  </ng-template>
  <p-footer>
    <button
      class="se-button btn btn-sm se-button--secondary"
      (click)="clearSelection(); multiSelect.hide()"
    >
      <span for="clear-option">Clear</span>
    </button>
    <button
      class="se-button btn btn-sm se-button--primary"
      (click)="saveSelection(); multiSelect.hide()"
    >
      <span for="saving-option">Save</span>
    </button>
  </p-footer>
</p-multiSelect>
