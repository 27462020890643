import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';

import { LoginComponent } from './login.component';
import { LoginBoxComponent } from './components/login-box/login-box.component';

import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  declarations: [LoginComponent, LoginBoxComponent],
  imports: [SharedModule, LoginRoutingModule],
})
export class LoginModule {}
