import { Injectable, Inject } from '@angular/core';
import {
  RedirectRequest,
  InteractionStatus,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';

import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VersionService } from '@core/services/VersioningService';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticated = false;
  private readonly _destroying$ = new Subject<void>();

  /**
   * Initializes the AuthService by setting up account change listeners and checking authentication status.
   * - Enables account storage events.
   * - Subscribes to MSAL account addition/removal events to handle authentication state changes.
   * - Checks version and active account status upon initialization and when authentication status changes.
   *
   * @param msalGuardConfig - Configuration for MSAL guard.
   * @param broadcastService - Service for broadcasting MSAL events.
   * @param authService - MSAL service for handling authentication.
   * @param versionService - Service for managing application versions.
   */
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private versionService: VersionService
  ) {
    this.authService.instance.enableAccountStorageEvents();

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.versionService.checkVersion();
          // this.setLoginDisplay();
        }
      });

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.versionService.checkVersion();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * Checks if the user is authenticated by verifying the presence of accounts in the MSAL instance.
   * Updates the `isAuthenticated` property based on whether there are any accounts.
   */
  checkAuthentication() {
    this.isAuthenticated =
      this.authService.instance.getAllAccounts().length > 0;
  }

  /**
   * Retrieves the name of the first account from the MSAL instance.
   * @returns {string} The name of the first account.
   */
  getAccountName() {
    return this.authService.instance.getAllAccounts()[0].name;
  }

  /**
   * Retrieves the GID (Global ID) from the first account's ID token claims.
   * @returns {string | null} The GID of the account if available; otherwise, null.
   */
  getAccountGID(): string | null {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const gid = accounts[0].idTokenClaims['gid'];
      if (gid) {
        return gid as string;
      }
    }
    return null;
  }

  /**
   * Retrieves the GID and account name, separated by ' | '.
   * @returns {string} A string combining the GID and account name.
   */
  getGIDAndAccoundName() {
    return this.getAccountGID() + ' | ' + this.getAccountName();
  }

  /**
   * Checks if the user is authenticated.
   * @returns {boolean} True if the user is authenticated; otherwise, false.
   */
  getAuthentication() {
    return this.isAuthenticated;
  }

  /**
   * Initiates the login process using a redirect.
   * Handles the redirection based on configuration and updates authentication status.
   */

  login() {
    this.authService.instance.handleRedirectPromise();

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }

    this.checkAuthentication();
  }

  /**
   * Logs out the user and redirects to the login page.
   * Completes any ongoing tasks and handles redirection after logout.
   */
  logout() {
    this.authService.instance.handleRedirectPromise();

    this._destroying$.next(undefined);
    this._destroying$.complete();

    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/#login',
    });
  }
}
