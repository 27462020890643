/**
 * @NgModule
 * Provides shared components, modules, and services used across the application.
 *
 * - Declarations:
 *   - Components for UI elements such as buttons, tables, calendars, dropdowns, and dialogs.
 *
 * - Imports:
 *   - CommonModule, FormsModule, ReactiveFormsModule for basic Angular functionality
 *   - FontAwesomeModule for icons
 *   - Angular Material and PrimeNG modules for UI components and features
 *   - ngx-infinite-scroll for infinite scrolling
 *
 * - Exports:
 *   - Common Angular and third-party modules
 *   - Shared components for easy reuse across the application
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// ANGULAR MATERIAL
import { MatTooltipModule } from '@angular/material/tooltip';

// PRIME NG
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// COMPONENTS
import { ButtonComponent } from './components/button/button.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { TableComponent } from './components/table/table.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FilterDropdownComponent } from './components/table/filter-dropdown/filter-dropdown.component';
import { MoreInfoAccordionComponent } from './components/table/more-info-accordion/more-info-accordion.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { CommentDialogComponent } from './components/table/comment-dialog/comment-dialog.component';

@NgModule({
  declarations: [
    HeroBannerComponent,
    ButtonComponent,
    TabsComponent,
    TableComponent,
    CalendarComponent,
    DropdownComponent,
    FilterDropdownComponent,
    MoreInfoAccordionComponent,
    LoadingSpinnerComponent,
    PersonCardComponent,
    CommentDialogComponent,
  ],
  imports: [
    AccordionModule,
    TableModule,
    CalendarModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    TooltipModule,
    MatTooltipModule,
    MultiSelectModule,
    DialogModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    InfiniteScrollModule,
    RouterModule,
  ],
  exports: [
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    TableModule,
    TooltipModule,
    OverlayPanelModule,
    HeroBannerComponent,
    FontAwesomeModule,
    TableComponent,
    CalendarModule,
    CalendarComponent,
    DropdownComponent,
    DialogModule,
    AccordionModule,
    ProgressSpinnerModule,
    LoadingSpinnerComponent,
    PersonCardComponent,
  ],
})
export class SharedModule {}
