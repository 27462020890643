<nav class="navbar navbar-expand navbar-light">
  <a class="navbar-brand" href="#">
    <img height="auto" width="auto" src="{{ logoUrl }}" />
    <p>{{ appTitle }}</p>
  </a>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <ng-container *ngFor="let item of headerLinks; let i = index">
        <li *ngIf="i < 3" class="nav-item">
          <a
            class="nav-link active"
            aria-current="page"
            routerLinkActive="currentLink"
            [routerLink]="[item.link.href]"
            [routerLinkActiveOptions]="{exact: true}"
            [target]="item.link.target"
          >{{ item.title }}</a
          >
        </li>
        <a
          *ngIf="i >= 3"
          class="nav-link active"
          aria-current="page"
          [href]="item.link.href"
          [target]="item.link.target"
        >{{ item.title }}</a
        >
      </ng-container>
    </ul>
  </div>
</nav>
