import { Component, Input, OnInit } from '@angular/core';
import { Link } from '@shared/models/link';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * FooterComponent displays the footer section of the application.
 * It shows the current application version and a list of links.
 */
export class FooterComponent implements OnInit {
  @Input()
  version!: string; // Application version

  @Input()
  links!: Link[]; // List of links to be displayed in the footer

  currentDate = Date.now();

  /**
   * On initialization, retrieves the application version from local storage.
   */
  ngOnInit() {
    this.version = localStorage.getItem('appVersion');
  }
}
