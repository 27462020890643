<footer class="footer">
  <div class="d-flex">
    <div class="d-flex">
      <span>&copy; Siemens Energy, {{ currentDate | date : "yyyy" }}</span>
    </div>
    <div class="d-flex ms-auto">
      <div class="d-flex align-items-center footer__version">
        <span class="text-small">{{ version }} </span>
      </div>
      <ng-container *ngFor="let link of links">
        <a
          class="pl-3"
          *ngIf="link.href"
          [href]="link.href"
          target="_blank"
          translate
        >
          {{ link.title }}
        </a>
      </ng-container>
    </div>
  </div>
</footer>
