/**
 * Converts a date string to the format "YYYY-MM-DD".
 * @param {string} dateStr - The date string to convert.
 * @returns {string} The formatted date string.
 */

export function convertDate(dateStr: string) {
  let date = new Date(dateStr);

  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  let day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
