/**
 * The `RisksModule` is an Angular module that handles the Risks feature of the application.
 *
 * It imports the `SharedModule` for shared components and directives, `RisksRoutingModule`
 * for routing configuration specific to the Risks feature, and `MultiSelectModule` from
 * PrimeNG for multi-select functionality.
 *
 * Components:
 * - `RisksComponent`: Main component for the Risks feature.
 * - `DialogAddActionPlanComponent`: Component for adding action plans via a dialog.
 */

import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';

import { RisksComponent } from './risks.component';
import { RisksRoutingModule } from './risks-routing.module';

import { MultiSelectModule } from 'primeng/multiselect';
import { DialogAddActionPlanComponent } from './components/dialog-add-action-plan/dialog-add-action-plan.component';

@NgModule({
  declarations: [RisksComponent, DialogAddActionPlanComponent],
  imports: [SharedModule, RisksRoutingModule, MultiSelectModule],
})
export class RisksModule {}
