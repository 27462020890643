<div class="login">
  <div
    class="login__bg"
    style="background-image: url('./assets/images/SE_header_image.png')"
  >
    <div class="container">
      <!-- <app-login-box></app-login-box> -->
      <div class="login-box">
        <h2>Welcome</h2>
        <p>Sign in with</p>
        <button class="btn btn-primary" (click)="login()">Azure AD</button>
      </div>
    </div>
  </div>
</div>
