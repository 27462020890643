// version.service.ts
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@core/services/auth.service';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

/**
 * Service for managing application version and local storage.
 */
export class VersionService {
  private storageKey = 'appVersion';
  private currentVersion$ = new BehaviorSubject(environment.version);
  private BASE_URL = environment.apiUrl;
  infoTooltipMessage$: BehaviorSubject<any> = new BehaviorSubject({});
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  /**
   * Checks and updates the current application version.
   * Clears local storage if the version has changed.
   */

  checkVersion(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.http.get(`${this.BASE_URL}db_version`),
      this.http.get(`${this.BASE_URL}popup`)
    ]).subscribe(([db_version, popup]: [any, any[]]) => {
      const storedVersion = localStorage.getItem(this.storageKey);
      const newVersion = Object.values(db_version[0])[0] as string;
      this.currentVersion$.next(newVersion);

      if (storedVersion && storedVersion !== newVersion) {
        this.clearLocalStorage();
        this.infoTooltipMessage$.next({
          severity: 'info',
          summary: 'New Version Available',
          detail: 'Please refresh the page to get the latest updates.',
          sticky: true
        });
      }
      localStorage.setItem(this.storageKey, newVersion);

      if (popup && popup.length > 0) {
        this.infoTooltipMessage$.next({
          summary:popup[0].TITLE,
          detail:popup[0].BODY,
          severity: 'warn',
          life: 20000
        });
      }

      this.isLoading$.next(false);
    });
  }

  /**
   * Clears local and session storage.
   */
  private clearLocalStorage(): void {
    localStorage.removeItem('appVersion');
    localStorage.removeItem('state-session-filters-risk');
    sessionStorage.clear();
  }

  /**
   * Gets the current application version as an observable.
   * @returns Observable<string> The current version.
   */
  getVersion(): Observable<string> {
    return this.currentVersion$;
  }
}
