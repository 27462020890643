import { Component, Input } from '@angular/core';

@Component({
  selector: 'se-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() icon: string; // Icon class name (e.g., "fa fa-edit")
  @Input() text: string; // Button text
  @Input() textColor: string; // Text color (CSS color value)
  @Input() size: string; // Button size (e.g., "small", "medium", "large")

  /**
   * Computes the button's CSS classes based on the size.
   * @returns {string} The computed button classes.
   */
  get buttonClasses(): string {
    let classes = [];
    if (this.size) {
      classes.push(this.size);
    }
    // Add any other common classes or styles you want here

    return classes.join(' ');
  }
}
