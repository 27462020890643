<div class="se-person-card">
  <p class="se-person-card__name">{{ person.name }}</p>
  <p class="se-person-card__role">{{ person.role }}</p>
  <div class="se-person-card__icon">
    <fa-icon [icon]="faBuilding"></fa-icon
    ><span>{{ person.organisation }}</span>
  </div>
  <div class="se-person-card__icon">
    <fa-icon [icon]="faEnvelope"></fa-icon>
    <a href="mailto:{{ person.email }}">{{ person.email }}</a>
  </div>
</div>
