import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@core/services/auth.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

/**
 * Component handling user login and logout functionality.
 * - Provides methods for user authentication and session management.
 */
export class LoginComponent {
  constructor(public router: Router, private authService: AuthService) {}

  /**
   * Initiates user login process.
   */
  login() {
    this.authService.login();
  }

  /**
   * Logs out the current user.
   */
  logout() {
    this.authService.logout();
  }

  /**
   * Checks if the user is authenticated.
   */
  isAuthenticated() {
    this.authService.getAuthentication();
  }
}
