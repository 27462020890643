import { enableProdMode } from '@angular/core';
import { environment } from '@environments/environment';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

/**
 * Bootstraps the Angular application.
 *
 * Enables production mode if the environment is set to production.
 * Initializes the Angular application by bootstrapping the AppModule.
 * Logs any bootstrap errors to the console.
 */
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
