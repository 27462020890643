import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@core/services/auth.service';
import { BackendService } from '@core/services/backend.service';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dialog-add-action-plan',
  templateUrl: './dialog-add-action-plan.component.html',
  styleUrls: ['./dialog-add-action-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

/**
 * Component for adding and managing action plans within a dialog.
 *
 * Handles form initialization, submission, and navigation actions related to action plans.
 */
export class DialogAddActionPlanComponent {
  currentStep = '';
  actionPlansFields: any;
  rowData: any;
  actionPlansDropdownValues: any;

  actionPlanForm: FormGroup;
  isCreatingActionPlan = false;
  actionPlanId: number = 0;

  faCircleCheck = faCircleCheck;

  /**
   * Constructor for initializing the dialog component.
   * @param ref - Reference to the dynamic dialog
   * @param config - Configuration data for the dialog
   * @param formBuilder - Form builder service for creating forms
   * @param authService - Service for authentication-related functions
   * @param backendService - Service for backend interactions
   * @param router - Router for navigation
   */
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private backendService: BackendService,
    private router: Router
  ) {
    this.actionPlansFields = this.config.data.actionPlansFields;
    this.rowData = this.config.data.rowData;
    this.actionPlansDropdownValues = this.config.data.actionPlansDropdownValues;

    this.currentStep = 'insertData';
  }

  /**
   * Initializes the form when the component is initialized.
   */
  ngOnInit() {
    this.setForm();
  }

  /**
   * Sets up the form with controls based on action plans fields.
   */
  setForm() {
    this.actionPlanForm = this.formBuilder.group(this.createFormControls());
  }

  /**
   * Creates form controls based on the fields defined for action plans.
   * @returns Form controls for each field
   */
  createFormControls() {
    return this.actionPlansFields.reduce((controls, category) => {
      category.values.forEach((value) => {
        const validators = [];
        if (value.isMandatory === 1 && value.isEditable === 1) {
          validators.push(Validators.required);
        }

        controls[value.id] = this.formBuilder.control(
          this.setControlValue(value.id, value.dataType),
          validators
        );
      });
      return controls;
    }, {});
  }

  /**
   * Sets the initial value for a form control based on its type and ID.
   * @param controlId - ID of the form control
   * @param type - Data type of the control
   * @returns Initial value for the control
   */
  setControlValue(controlId, type) {
    let controlValue = '';
    if (controlId === 'CREATED_BY') {
      controlValue = this.authService.getGIDAndAccoundName();
    } else if (
      // Set inicial values for Date fields as null
      type === 'Date'
    )
      controlValue = null;
    else if (controlId === 'START_DATE_ACTION_PLAN') {
      controlValue = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    } else if (type === 'Integer' && controlId !== 'ID_UNIQUE_RISK') {
      controlValue = '0';
    } else {
      // Get value from valuesData or use an empty string
      controlValue = this.rowData[controlId] || '';
    }

    return controlValue;
  }

  /**
   * Handles form submission and sends data to the backend service.
   */
  onSubmit() {
    if (this.actionPlanForm.valid) {
      this.isCreatingActionPlan = true;
      this.currentStep = 'addingData';
      this.backendService
        .addActionPlan({
          ...this.actionPlanForm.value,
          ID_UNIQUE_RISK: this.rowData.ID_UNIQUE_RISK,
        })
        .subscribe((data) => {
          this.actionPlanId = data;
          this.isCreatingActionPlan = false;
        });
    } else {
      console.log('Form is invalid. Please check your inputs.');
    }
  }

  /**
   * Closes the dialog and returns 'closed' status.
   */
  closeDialog() {
    this.ref.close('closed');
  }

  /**
   * Navigates to the action plans page with query parameters from the form.
   */
  viewActionPlans() {
    const queryParams = {
      IFA_NUMBER: this.actionPlanForm.value.IFA_NUMBER,
      SEGMENT_CODE: this.actionPlanForm.value.SEGMENT_CODE,
      LOCATION_CODE: this.actionPlanForm.value.LOCATION_CODE,
    };

    this.router.navigate(['action-plans'], { queryParams });

    // close dialog and move to action plans screen
    this.closeDialog();
  }

  /**
   * Resets the form to add more action plans.
   */
  addMorePlans() {
    this.currentStep = 'insertData';
    this.actionPlanId = 0;
    this.setForm();
  }
}
