import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navbar-primary',
  templateUrl: './navbar-primary.component.html',
  styleUrls: ['./navbar-primary.component.scss'],
})
export class NavbarPrimaryComponent {
  @Input() logoUrl: string | undefined;
  @Input() appTitle: string | undefined;
  @Input() headerLinks: any;

  constructor() {}
}
