import { Component } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent {
  initialValue: string;
  currentValue: string;

  /**
   * Constructs a new instance of CommentDialogComponent.
   * @param {DynamicDialogConfig} config - Configuration data for the dialog.
   * @param {DynamicDialogRef} ref - Reference to the dialog instance.
   */

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.initialValue = this.config.data.value;
    this.currentValue = this.config.data.value;
  }

  /**
   * Closes the dialog and returns the current value.
   */
  saveComment() {
    this.ref.close(this.currentValue);
  }
}
