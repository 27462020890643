import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  faCircleInfo,
  faFilter,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { BackendService } from '@core/services/backend.service';

import {
  TableHeader,
  TableHeaderGroup,
  TableConfig,
} from '@shared/models/table.model';

import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import {Table, TableLazyLoadEvent} from 'primeng/table';

import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { convertDate } from '@shared/helpers/date-transformation';

@Component({
  selector: 'se-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class TableComponent {
  @ViewChild('dt') dt: Table;
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('opRangeDates') opRangeDates: OverlayPanel;
  @ViewChild('minValueInput') minValueInput: ElementRef;
  @ViewChild('maxValueInput') maxValueInput: ElementRef;

  @Input() tableHeadersGroup: TableHeaderGroup[];
  @Input() tableHeaders: TableHeader[];
  @Input() tableData: any[];
  @Input() tableConfig: TableConfig;
  @Input() tableFilters: any;
  @Input() tableTotalValues: number;
  @Input() tableDropdowns: any;
  @Input() valuesFiltered: any;
  @Input() totalActionPlans: number = null;
  @Input() dataLoading: boolean = false;

  selectedRow!: any;
  panelExpansionStates: { [id: string]: boolean } = {};
  rangeDates: Date[] | undefined = [];

  @Output() eventActions = new EventEmitter<{ action: string; row: any }>();
  @Output() updateRow = new EventEmitter<any>();
  @Output() eventFilters = new EventEmitter<any>();
  @Output() loadMore = new EventEmitter<any>();
  @Output() fetchRelevantFilters = new EventEmitter<any>();

  //icons
  faCircleInfo = faCircleInfo;
  faFilter = faFilter;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  defaultDate: Date | null;

  loading$ = this.backendService.loading$;
  private leftValues = [];

  @ViewChild('toast') toast: any;
    View: any;
    currentRoute: string;

  /**
   * Constructs a new instance of the component.
   *
   * @param {BackendService} backendService - Service for backend operations.
   * @param {DialogService} dialogService - Service for dialog management.
   * @param {MessageService} messageService - Service for message notifications.
   * @param {Router} router - Service for navigation.
   */
  constructor(
    private backendService: BackendService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private router: Router
  ) {}

  /**
   * Initializes the component.
   *
   * @description
   * Subscribes to loading states and performs sticky column calculations once loading is complete.
   * Also handles and displays errors from the backend service.
   */
  ngOnInit() {
    if (this.tableConfig.screenName === 'risk') {
      this.defaultDate = new Date();
    } else {
      this.defaultDate = null;
    }

    combineLatest([
      this.backendService.loadingFilters$,
      this.backendService.loading$,
    ]).subscribe(([loadingFilters, loading]) => {
      if (!loadingFilters && !loading) {
        setTimeout(() => {
          this.calculateStickyColumnWidths();
          this.applyStickyColumnsOnBody();
        }, 50);
      }
    });

    /**
     * Subscribes to backend service errors and displays an error message.
     * @param {string} error - The error message from the backend service.
     */
    this.backendService.error$.subscribe((error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
      });
    });
    this.isRiskPage();
  }

  isRiskPage() {
    // Logic to determine if it's the first screen
    this.currentRoute = this.router.url;
  }

  /**
   * Constructs a string of CSS classes for a table column based on the provided parameters.
   *
   * @param {boolean} hasRightSeparator - Adds a right separator class if true.
   * @param {boolean} isDarkBkg - Adds a dark background class if true.
   * @param {boolean} colWidth - Adds a custom column width class if true.
   * @param {number} columnIdx - The column index for sticky column classes.
   * @returns {string} - The generated CSS class string.
   *
   * @description
   * Returns a string of CSS classes including:
   * - 'se-table__separator' for a right separator.
   * - 'se-table__bg-dark' for a dark background.
   * - 'column-width' for custom width.
   * - 'sticky-col' and 'sticky-col-{columnIdx}' if the column is sticky.
   *
   * @example
   * const classes = addClasses(true, false, true, 1); // "se-table__separator column-width sticky-col sticky-col-1"
   */
  addClasses(hasRightSeparator, isDarkBkg, colWidth, columnIdx) {
    let classes = '';

    if (hasRightSeparator) {
      classes += 'se-table__separator ';
    }
    if (isDarkBkg) {
      classes += 'se-table__bg-dark ';
    }
    if (colWidth) {
      classes += 'column-width ';
    }
    if (
      this.tableConfig.nrColumnsFreeze &&
      columnIdx < this.tableConfig.nrColumnsFreeze
    ) {
      classes += 'sticky-col ' + 'sticky-col-' + columnIdx;
    }
    return classes;
  }

  /**
   * Applies sticky positioning to table columns using left offset values.
   *
   * @description
   * Sets the `left` CSS property for elements with classes `.sticky-col-{index}` based on `leftValues` offsets.
   */
  applyStickyColumnsOnBody() {
    this.leftValues.forEach((left, index) => {
      const stickyCols = document.querySelectorAll(
        '.sticky-col-' + index
      ) as NodeListOf<HTMLElement>;

      stickyCols.forEach((col) => {
        col.style.left = left + 'px';
      });
    });
  }

  /**
   * Calculates and sets widths for sticky columns in the table.
   *
   * @description
   * Sets the `left` CSS property for sticky column headers and updates `leftValues` with their positions.
   */
  calculateStickyColumnWidths() {
    const table = document.querySelector('.se-table') as HTMLElement;
    const stickyCols = table.querySelectorAll(
      '.se-table__header.sticky-col'
    ) as NodeListOf<HTMLElement>;

    let left = 1;

    stickyCols.forEach((col, index) => {
      col.style.left = left + 'px';
      this.leftValues[index] = left;
      left += col.offsetWidth;
    });
  }

  /**
   * Emits a custom sorting event based on user interaction with a table column.
   *
   * @param {any} event - The event object containing sorting details, including the sort order and column field.
   *
   * @description
   * This function determines the sorting order ('ASC' or 'DESC') from the event object and identifies the column to be sorted.
   * It then emits a sorting event with the order, column ID, and a flag indicating that sorting is applied.
   */
  customSort(event: any) {
    const order = event.order === 1 ? 'ASC' : 'DESC';
    const colId = event.field;
    this.eventFilters.emit({ order, colId, isSort: true });
  }

  /**
   * Emits an event for a row action in the table.
   *
   * @param {string} action - The type of action to perform (e.g., 'edit', 'delete').
   * @param {any} row - The data or identifier of the row on which the action is to be performed.
   *
   * @description
   * This function emits an event with the specified action and row data, allowing for custom handling of row-based actions.
   */
  rowAction(action, row) {
    this.eventActions.emit({ action, row });
  }

  /**
   * Transforms and emits a filter list for a table column.
   *
   * @param {Array<{ label: string }>} selectedValues - An array of selected filter values, each with a `label` property.
   * @param {string} colId - The identifier of the column for which the filters are applied.
   *
   * @description
   * This function processes the `selectedValues` by replacing commas in each label with dollar signs,
   * then joins the transformed labels into a comma-separated string. It emits an event with the transformed
   * filter list and the column ID to update the parent page with the new filter values.
   */
  transformedFilterList(selectedValues, colId) {
    let valuesList = null;
    if (selectedValues && selectedValues.length > 0) {
      const transformedList = selectedValues.map((item) =>
        item.label.replace(/,/g, '$')
      );
      valuesList = transformedList.join(',');
    }

    //send filters to update parent page values
    this.eventFilters.emit({ valuesList, colId });
  }

  fetchValuesForColumn(event, colId){
    this.fetchRelevantFilters.emit(colId);
  }

  /**
   * Toggles the expansion state of a panel for a specific column.
   *
   * @param {string} colId - The identifier of the column whose panel expansion state is to be toggled.
   *
   * @description
   * This function switches the expansion state of the panel associated with the given column ID.
   * If the panel is currently expanded, it will be collapsed, and vice versa.
   */
  togglePanelExpansion(colId: string) {
    this.panelExpansionStates[colId] = !this.panelExpansionStates[colId];
  }

  /**
   * Toggles the visibility of an overlay based on the provided event.
   *
   * @param {Event} event - The event object triggering the overlay toggle action.
   *
   * @description
   * This function calls the `toggle` method on `opRangeDates` with the given event to show or hide an overlay.
   */
  toggleOverlay(event: Event) {
    this.opRangeDates.toggle(event);
  }

  /**
   * Transforms a cell value based on its data type.
   *
   * @param {string | null} dateString - The value to transform.
   * @param {string} dataType - The type of transformation ('Timestamp' or 'Numeric').
   * @returns {string | null} - The transformed value.
   *
   * @description
   * Formats `dateString` based on `dataType`:
   * - 'Timestamp': Trims to the first 10 characters.
   * - 'Numeric': Adds thousand separators.
   */
  tranformCellValue(dateString, dataType) {
    if (dateString === null) return dateString;

    if (dataType === 'Timestamp') return dateString.substring(0, 10);
    if (dataType === 'Numeric') {
      const numParts = dateString.toString().split('.');
      numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return numParts[0];
    }

    return dateString;
  }

  /**
   * Emits an update event with new row data.
   *
   * @param {any} value - The new value to update.
   * @param {string} colId - The column ID being updated.
   * @param {any} rowData - The data of the row being updated.
   * @param {number} [rowIndex] - The index of the row (optional).
   * @param {any} [columnData] - Additional column data (optional).
   *
   * @description
   * Emits an event with `value`, `colId`, `rowData`, and optionally `rowIndex` and `columnData` to update row information.
   */
  updateValue(value, colId, rowData, rowIndex?, columnData?) {
    this.updateRow.emit({ value, colId, rowData, rowIndex, columnData });
  }

  /**
   * Converts a string to a number.
   *
   * @param {string} value - The string to convert.
   * @returns {number} - The numeric value.
   *
   * @description
   * Parses `value` as an integer and returns it.
   */
  getNumericValue(value: string): number {
    return parseInt(value);
  }

  /**
   * Opens a dialog to edit a field and handles updates.
   *
   * @param {string} value - The current value to display in the dialog.
   * @param {string} colId - The column ID of the field being edited.
   * @param {any} rowData - The data of the row containing the field.
   * @param {number} rowIndex - The index of the row.
   * @param {any} columnData - Additional column data.
   * @returns {DialogRef} - The reference to the opened dialog.
   *
   * @description
   * Opens a dialog for editing a field, emits an update event with new values upon dialog closure,
   * and maintains ARIA accessibility attributes.
   */
  openLongtextDialog(value, colId, rowData, rowIndex, columnData) {
    const ref = this.dialogService.open(CommentDialogComponent, {
      header: 'Edit field ' + columnData.title,
      dismissableMask: true,
      data: { colId, value, rowData, rowIndex },
    });

    ref.onClose.subscribe((value) => {
      if (value !== undefined) {
        this.updateRow.emit({
          value,
          colId,
          rowData,
          rowIndex,
          columnData,
          isComment: true,
        });
      }
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    return ref;
  }

  /**
   * Navigates to the action plans page.
   *
   * @description
   * Uses the router to navigate to the 'action-plans' route.
   */
  viewActionsPlans()  {
    this.router.navigate(['action-plans']);
  }

  /**
   * Resets filters and sorting for the table.
   *
   * @description
   * Clears the table filters and sorting on the UI, removes related items from session and local storage,
   * and emits an event to reset filters.
   */
  resetFilterAndOrder() {
    // clear filter on UI
    this.dt.reset();

    // delete values on local storage
    sessionStorage.removeItem(
      'state-session-order-' + this.tableConfig.screenName
    );
    localStorage.removeItem(this.tableConfig.screenName + 'TableFilters');;

    // reset filters
    this.eventFilters.emit();
  }

  /**
   * Sets PVO filters and closes the filter box.
   *
   * @description
   * Emits the lower and upper PVO limits from `valuesFiltered` and then closes the filter box.
   */
  setPVOFilters() {
    this.emitPVOValuesAndCloseBox(
      this.valuesFiltered['PVO_LOWER_LIMIT'],
      this.valuesFiltered['PVO_UPPER_LIMIT']
    );
  }

  /**
   * Resets PVO values and closes the filter box.
   *
   * @description
   * Emits `null` for both lower and upper PVO limits and then closes the filter box.
   */
  resetPVOValues() {
    this.emitPVOValuesAndCloseBox(null, null);
  }

  /**
   * Emits PVO filter values and closes the filter box.
   *
   * @param {number | null} minValue - The minimum PVO value.
   * @param {number | null} maxValue - The maximum PVO value.
   *
   * @description
   * Emits the specified `minValue` and `maxValue` with an `isPVO` flag set to true, then hides the filter box.
   */
  emitPVOValuesAndCloseBox(minValue, maxValue) {
    this.eventFilters.emit({ minValue, maxValue, isPVO: true });
    this.op.hide();
  }

  /**
   * Checks if PVO filters are set.
   *
   * @returns {boolean} - `true` if either PVO lower or upper limit is set; otherwise `false`.
   *
   * @description
   * Returns `true` if either `PVO_LOWER_LIMIT` or `PVO_UPPER_LIMIT` in `valuesFiltered` is not null.
   */
  hasFilterPVO() {
    return (
      this.valuesFiltered['PVO_LOWER_LIMIT'] != null ||
      this.valuesFiltered['PVO_UPPER_LIMIT'] != null
    );
  }

  /**
   * Sets estimated due dates filters and closes the filter box.
   *
   * @param {string} col_id - The column ID for which the filters are applied.
   *
   * @description
   * Converts the start and end dates from `rangeDates` to a specified format and emits these values,
   * then closes the filter box.
   */
  setEstimatedDueDatesFilters(col_id: string) {
    const minDate  = convertDate(this.rangeDates[0].toDateString());
    const maxDate = convertDate(this.rangeDates[1].toDateString());
    this.emitEstimatedDueDatesValuesAndCloseBox(minDate, maxDate, col_id);
  }

  loadCarsLazy(event: TableLazyLoadEvent) {
    if (event.first + event.rows >= this.tableData.length && !this.dataLoading) {
      if(this.tableConfig.hasLoadMore && this.tableData.length < this.tableTotalValues){
        this.loadMore.emit()
        event.forceUpdate();
      }
    }
  }

  /**
   * Resets estimated due dates filters and closes the filter box.
   *
   * @param {string} col_id - The column ID for which the filters are reset.
   *
   * @description
   * Clears the `rangeDates` and emits `null` values for the estimated due dates, then closes the filter box.
   */
  resetEstimatedDueDatesValues(col_id: string) {
    this.rangeDates = [];
    this.emitEstimatedDueDatesValuesAndCloseBox(null, null, col_id);
  }

  /**
   * Emits estimated due dates values and closes the filter box.
   *
   * @param {string | null} minDate - The minimum due date.
   * @param {string | null} maxDate - The maximum due date.
   * @param {string} col_id - The column ID for which the values are emitted.
   *
   * @description
   * Emits `minDate`, `maxDate`, and a flag indicating estimated due dates, then hides the filter box.
   */
  emitEstimatedDueDatesValuesAndCloseBox(minDate, maxDate, col_id: string) {
    this.eventFilters.emit({
      minDate,
      maxDate,
      isEstimatedDueDates: true,
      col_id,
    });
    this.opRangeDates.hide();
  }

  /**
   * Checks if estimated due dates filters are set for a specific column.
   *
   * @param {string} col_id - The column ID to check filters for.
   * @returns {boolean} - `true` if either min or max date filter is set; otherwise `false`.
   *
   * @description
   * Returns `true` if either the minimum or maximum estimated due date filter for the given `col_id` is not null.
   */
  hasFilterEstimatedDueDates(col_id: string) {
    return (
      this.valuesFiltered[`${col_id}_MIN`] != null ||
      this.valuesFiltered[`${col_id}_MAX`] != null
    );
  }
}
