/**
 * Capitalizes the first letter of the input string.
 * @param {string} input - The string to capitalize.
 * @returns {string} The string with the first letter in uppercase.
 */

export function firstLetterUpperCase(input: string): string {
  if (input == null) {
    return input;
  }
  const lowercasedString = input.toLowerCase();
  return lowercasedString.charAt(0).toUpperCase() + lowercasedString.slice(1);
}
