/**
 * @NgModule
 * Configures routes for the application with lazy-loaded modules and guards.
 *
 * - Routes:
 *   - `''`: Lazy-loads LandingModule and applies MsalGuard for authentication.
 *   - `'risks'`: Lazy-loads RisksModule.
 *   - `'action-plans'`: Lazy-loads ActionPlansModule.
 *   - `'**'`: Displays PageNotFoundComponent for unmatched routes.
 *
 * - RouterModule Config:
 *   - Hash-based URL strategy
 *   - Conditional initial navigation based on iframe or popup
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

// pages
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/landing/landing.module').then(
            (m) => m.LandingModule
          ),
        pathMatch: 'full',
      },
      {
        path: 'risks',
        loadChildren: () =>
          import('./features/risks/risks.module').then((m) => m.RisksModule),
      },
      {
        path: 'action-plans',
        loadChildren: () =>
          import('./features/action-plans/action-plans.module').then(
            (m) => m.ActionPlansModule
          ),
      },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./features/login/login.module').then((m) => m.LoginModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
