import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'se-more-info-accordion',
  templateUrl: './more-info-accordion.component.html',
  styleUrls: ['./more-info-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MoreInfoAccordionComponent {
  @Input() supportingInfo: any[];
  @Input() isOpen: boolean;
}
