/**
 * Environment Configuration
 * This configuration is used for the development environment.
 */
export const environment = {
  /**
   * Production Flag
   * Indicates if the application is running in production mode.
   */
  production: false,
  version: '3.4',

  /**
   * API URL
   * The base URL for the application's API endpoints.
   */
  apiUrl:
    'https://api-supplier-capability-assessment-dev.edaa.siemens-energy.cloud/',

  /**
   * Azure Active Directory (Azure AD) Configuration
   */
  azureAD: {
    /**
     * Client ID
     * The client ID used for authenticating the application with Azure AD.
     */
    clientId: '9a939a65-f63e-4960-84ea-86d6f3d676f3',

    /**
     * Authority
     * The authority URL for Azure AD authentication.
     */
    authority:
      'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477',

    /**
     * Redirect URI
     * The URI to redirect to after successful authentication.
     */
    redirectUri: 'https://superis-dev.edaa.siemens-energy.cloud/',

    /**
     * API Configuration
     */
    apiConfig: {
      /**
       * Scopes
       * The scopes required for accessing the API.
       */
      scopes: ['9a939a65-f63e-4960-84ea-86d6f3d676f3/.default'],

      /**
       * URI
       * The URI for the API used for authentication.
       */
      uri: 'https://api-supplier-capability-assessment-dev.edaa.siemens-energy.cloud/',
    },
  },
};
