import { Component, Input } from '@angular/core';

import { faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'se-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent {
  @Input() person: any;

  // icons
  faEnvelope = faEnvelope;
  faBuilding = faBuilding;
}
