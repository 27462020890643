<div class="se-calendar">
  <p-calendar
    [(ngModel)]="date"
    dateFormat="yy-mm-dd"
    dataType="string"
    timeZone="UTC"
    [showButtonBar]="true"
    appendTo="body"
    [class.ng-invalid]="isInvalid"
    [class.ng-dirty]="isInvalid"
    (ngModelChange)="onDateChange($event)"
    [disabled]="isDisabled"
  >
  </p-calendar>
  <fa-icon class="se-calendar__icon" [icon]="faCalendar"></fa-icon>
</div>
